let serverClientTimeDiff: number | undefined
let fetchPromise: Promise<void> | undefined

function fetchTimeDiff(): Promise<void> | undefined {
  if (fetchPromise) {
    return
  }

  fetchPromise = (async () => {
    try {
      const response = await fetch('https://time.akamai.com/?iso')
      if (!response.ok) {
        throw new Error('A network error occurred when fetching server time')
      }
      const serverTimeISO = await response.text()
      const serverTime = new Date(serverTimeISO)
      serverClientTimeDiff = serverTime.getTime() - Date.now()
    } catch (error) {
      console.error('Error fetching server time:', error)
      serverClientTimeDiff = 0
    }
  })()
}

export const getCurrentTimeDiff = () => serverClientTimeDiff

// The history behind this is that we dont want to load live event countdowns
// before the event has started, and some devices seem to have the wrong time set
export const getServerTime = () => {
  if (serverClientTimeDiff === undefined) {
    fetchTimeDiff()
    return new Date(Date.now())
  }
  return new Date(Date.now() + serverClientTimeDiff)
}
