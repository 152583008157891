import { StrictMode, useCallback, useEffect, useState } from 'react'
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import '@tv4/avod-web-player/dist/avod-web-player.all.css'
import 'intersection-observer'
import type { AbstractIntlMessages } from 'next-intl'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import Head from 'next/head'
import type { NextRouter } from 'next/router'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { ThemeProvider } from 'styled-components'
import { StyleSheetManager } from '@nordic-web/ui-styles'
import { theme } from '@nordic-web/ui-theme'
import { isClientSide, isServerSide } from '@nordic-web/utils/misc/detect-side'
import { KindlyChat } from '@/components/kindly-chat'
import { NetworkListener } from '@/components/network-listener/network-listener'
import { OnAuthChange } from '@/components/on-auth-change'
import { ServiceMessages } from '@/components/service-messages/service-messages'
import { brandConfig } from '@/config/brand'
import { ContextsProvider } from '@/context/contexts-provider'
import { FeatureToggleProvider, FeatureToggleRefresher } from '@/context/feature-toggle-context'
import { TranslationProvider } from '@/context/translation-provider'
import { AuthModal } from '@/features/auth/components/auth-modal'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { AuthModalPaths } from '@/features/auth/types'
import { RenoNotificationListener } from '@/features/reno/reno-notification-listener'
import { SearchModal } from '@/features/search/search-modal'
import { ProfilingConsentModal } from '@/features/user/components/profiling-consent-modal'
import { nextConfig } from '@/helpers/env'
import { useCheckAppAge } from '@/hooks/use-check-app-age'
import { useLogout } from '@/hooks/use-logout'
import { NordicApp } from '@/layouts/app'
import { AppMetaTags } from '@/layouts/app-meta-tags'
import type { ErrorBoundaryProps } from '@/layouts/error-boundary'
import { ErrorBoundary } from '@/layouts/error-boundary'
import { gtmScript as GTM } from '@/layouts/gtm-script'
import { OneTrustScript as OneTrust } from '@/layouts/onetrust-script'
import { ApolloProvidersWrapper } from '@/lib/apollo/apollo-providers-wrapper'
import { initApollo } from '@/lib/apollo/init-apollo'
import { createQueryClient } from '@/lib/react-query/create-query-client'
import { configureRestClients } from '@/pages/configure-rest-clients'
import { ForceHideWebviewBannersStyle } from '@/styles/force-hide-webview-banners'
import '@/styles/global-font-faces.css'
import { GlobalStyles } from '@/styles/global-styles'
import { PageTracking } from '@/tracking/page-tracking'
import { SnowplowTrackInitiate } from '@/tracking/snowplow/snowplow'
import { detectTouch } from '@/utils/detect-touch'
import ErrorPage from './_error.page'
import type { Page } from './types'

configureRestClients()

// Fixes issue where the scroll position is set before the page content is fully loaded.
// The issue is currently our slider component that loads to slow.
// Can probably be removed when we upgrade our slider.
// https://linear.app/tv4/issue/NW1-1668/keep-scroll-position-on-back-navigation
const fixScrollIssue = () => {
  if (history.state?.options?.scroll === false) return
  const key = `__next_scroll_${history.state.key}`
  const value = sessionStorage.getItem(key)
  const jsonValue = JSON.parse(value ?? 'null') ?? { y: 0 }
  if (typeof jsonValue?.y !== 'number') return
  document.documentElement.scrollTop = jsonValue?.y
}

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => {
  NProgress.done()
  fixScrollIssue()
})
Router.events.on('routeChangeError', () => NProgress.done())

const AuthRouteHandler = ({ router }: { router: NextRouter }) => {
  const { logout } = useLogout()
  const { dispatch: contextDispatch } = useAuthModal()
  const queries = Object.keys(router.query)
  const query = queries?.[0]

  const handleAuthQueryParameter = useCallback(() => {
    if (query) {
      if (query === brandConfig.authQueryParameters.logIn) {
        contextDispatch({ type: 'show-auth-modal', path: AuthModalPaths.LOGIN })
      }
      if (query === brandConfig.authQueryParameters.logOut) {
        logout()
      }
      if (query === brandConfig.authQueryParameters.signUp) {
        contextDispatch({ type: 'show-auth-modal', path: AuthModalPaths.SIGNUP })
      }
    }
  }, [query, contextDispatch, logout])

  // Handle auth query parameter on page load
  useEffect(() => {
    setTimeout(handleAuthQueryParameter, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      contextDispatch({ type: 'close-auth-modal' })
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [contextDispatch, router.events])

  return null
}

type InitialProps = {
  apolloClient?: ApolloClient<NormalizedCacheObject>
  translationsFromServer?: AbstractIntlMessages
  apolloStateFromServer: NormalizedCacheObject
}

const AppErrorBoundary = (_: ErrorBoundaryProps) => {
  return <ErrorPage statusCode={500} />
}

const CustomApp = (props: AppProps & InitialProps) => {
  useCheckAppAge()

  const [queryClient] = useState(() => createQueryClient())
  const { Component, router, apolloClient, pageProps } = props

  const { apolloStateFromServer, translationsFromServer } = isClientSide ? window.__NEXT_DATA__.props : props

  const withAppLayout = (Component: Page, pageProps: Record<string, string>) => {
    if (Component.getLayout) return Component.getLayout(Component, pageProps, router.query)

    return (
      <NordicApp>
        <Component {...pageProps} />
      </NordicApp>
    )
  }

  useEffect(() => {
    document.body.classList.add(detectTouch() ? 'touch' : 'no-touch')
  }, [])

  const nextRouter = useRouter()

  return (
    <StyleSheetManager>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ForceHideWebviewBannersStyle />

        <StrictMode>
          <Head>
            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
          </Head>
          <GTM.Head path={router.asPath} />

          <QueryClientProvider client={queryClient}>
            <FeatureToggleProvider>
              <TranslationProvider translationsFromServer={translationsFromServer}>
                <AppMetaTags />
                <ErrorBoundary FallbackComponent={AppErrorBoundary}>
                  <ApolloProvidersWrapper apolloClient={apolloClient} stateFromServer={apolloStateFromServer ?? null}>
                    <OneTrust />
                    <ContextsProvider>
                      <SnowplowTrackInitiate sendPageViewOnInit />
                      <FeatureToggleRefresher />
                      <AuthRouteHandler router={nextRouter} />
                      <PageTracking />
                      <OnAuthChange />
                      <RenoNotificationListener />
                      <NetworkListener />
                      {withAppLayout(Component, pageProps)}
                      <ReactQueryDevtools initialIsOpen={false} />
                      <KindlyChat />
                      <SearchModal />
                      <AuthModal />
                      <ProfilingConsentModal />
                      <ServiceMessages />
                    </ContextsProvider>
                  </ApolloProvidersWrapper>
                </ErrorBoundary>
              </TranslationProvider>
            </FeatureToggleProvider>
          </QueryClientProvider>
        </StrictMode>
      </ThemeProvider>
    </StyleSheetManager>
  )
}

type CustomAppContext = AppContext & {
  Component: Page
}

CustomApp.getInitialProps = async function (context: CustomAppContext) {
  const ctx = await App.getInitialProps(context)

  if (isServerSide) {
    const { AppTree } = context.ctx

    const apolloClient = initApollo()
    const translationsFromServer = await import(`@nordic-web/nordic-translations/config/${brandConfig.language}.json`)

    const appProps = {
      ...ctx,
      translationsFromServer,
      apolloClient,
    }

    let apolloStateFromServer

    // We cant use SSR in our PR deploys, the render instances are not in aws and gets blocked by the gateway firewall
    if (!nextConfig.bool('DISABLE_SSR')) {
      // SSR inspiration: https://github.com/vercel/next.js/issues/9336#issuecomment-1092830219
      const { getDataFromTree } = await import('@apollo/client/react/ssr')
      await getDataFromTree(<AppTree {...appProps} />)
      apolloStateFromServer = apolloClient.cache.extract()
    }

    // The props returned here are serialised to a json string to the client on SSR, which is then used to hydrate the client
    return {
      ...appProps,
      apolloStateFromServer,
    }
  }

  return ctx
}

// ts-prune-ignore-next
export default CustomApp
