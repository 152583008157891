import { resolveHref } from 'next/dist/client/resolve-href'
import type { NextRouter } from 'next/router'
import type { UrlObject } from 'url'
import type { ResultCodeEnum } from '@nordic-web/rest-codegen/generated/subscription'
import { BRAND, Brand, brandConfig } from '@/config/brand'
import type { CdpTab } from '@/features/cdp/tabs'
import { NEWS_CDP_ID } from './constants'
import { allPaths } from './paths'
import type { PathKey } from './translated-paths'

export const SPORTPAGE_ID = 'sport'

const { translatedPaths } = brandConfig

export declare type Url = string | UrlObject

// Ask Emil Öberg how this works. Basically used because we want to have all optional query to be an optional function parameter in the Path type.
type IncludeVoidIfEqual<X, Y> = [X, Y] extends [Y, X] ? X | void : X
type IncludeVoidIfAllPartial<T> = IncludeVoidIfEqual<T, Partial<T>>

type UrlFn<T, Q> = (query: IncludeVoidIfAllPartial<T & Q>) => UrlObject & { query: Q }
type UrlStringFn<T, Q> = (query: IncludeVoidIfAllPartial<T & Q>) => string

type Path<T, Q = unknown> = {
  urlString: UrlStringFn<T, Q>
  isActive: (router: NextRouter) => boolean
}

const isActivePath = (router: NextRouter, key: PathKey) =>
  router.asPath.indexOf(allPaths[key].mtv) === 0 || router.asPath.indexOf(allPaths[key].tv4) === 0

function defaultUrlFn(pathname?: string, hashParams: string[] = [], query: Record<string, string | undefined> = {}) {
  const realQuery = { ...query }
  hashParams.forEach((k) => delete realQuery[k])
  const hashParamKey = hashParams.find((k) => query[k]) ?? ''
  const hash = query[hashParamKey]

  const q = Object.keys(realQuery)
    .filter((key) => realQuery[key] != null)
    .reduce((result, key) => ({ ...result, [key]: realQuery[key] }), {})

  return {
    pathname: pathname,
    query: q,
    hash,
  }
}

function defaultIsActiveFn(pathname: string | undefined, router: NextRouter) {
  return router.asPath === pathname
}

function createPathFunctions<T extends UrlObject['query'] = undefined, Q = unknown>({
  pathname,
  hashParams = [],
  urlFn: _urlFn,
  isActiveFn: _isActiveFn,
}: {
  pathname?: string
  hashParams?: string[]
  urlFn?: UrlFn<T, Q>
  isActiveFn?: (router: NextRouter) => boolean
}): Path<T, Q> {
  const urlFn = _urlFn ?? defaultUrlFn.bind(null, pathname, hashParams)
  const isActive = _isActiveFn ?? defaultIsActiveFn.bind(null, pathname)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TODO fix, how can null be passed here but its not a nullable parameter?
  const getUrlString: UrlStringFn<T, Q> = (query) => resolveHref(null, urlFn(query), true)[1]

  return {
    urlString: getUrlString,
    isActive: isActive,
  }
}

declare type AssetIdQuery = {
  assetId: string
  slug: string
}

export type ClipsPathQuery = {
  assetId?: string
  slug?: string
  section?: string
  first?: string
  playlist?: string
  isAutoplay?: string
  params?: string[]
}

const clipsPath = createPathFunctions<ClipsPathQuery>({
  urlFn: function (args) {
    // We dont want to forward the params parameter, its an alias for the assetId
    const { params: _, ...query } = args ?? {}
    if (query?.assetId && query.slug) {
      return defaultUrlFn(`${translatedPaths.clip}/[assetId]/[slug]`, [], query)
    }
    if (query?.assetId) {
      return defaultUrlFn(`${translatedPaths.clip}/[assetId]`, [], query)
    }
    return defaultUrlFn(translatedPaths.clip, [], query ?? {})
  },
  isActiveFn: (router) => isActivePath(router, 'clip'),
})

type ProgramsPathQuery = { id: string; tab?: CdpTab; slug?: string }
const programsPath = createPathFunctions<ProgramsPathQuery>({
  urlFn: function (query) {
    if (BRAND === Brand.TV4 && query?.id === NEWS_CDP_ID) {
      return defaultUrlFn(translatedPaths.news)
    }
    if (query?.slug) {
      return defaultUrlFn(`${translatedPaths.contentDetailsPage}/[id]/[slug]`, [], query)
    }
    return defaultUrlFn(`${translatedPaths.contentDetailsPage}/[id]`, [], query)
  },
  isActiveFn: (router) => isActivePath(router, 'contentDetailsPage'),
})

const pagePath = createPathFunctions<{ id: string }>({
  urlFn: function (query) {
    if (query.id === SPORTPAGE_ID) {
      return defaultUrlFn(translatedPaths.sport)
    }

    return defaultUrlFn(`${translatedPaths.categories}/[id]`, [], query)
  },
  isActiveFn: (router) => isActivePath(router, 'categories'),
})

export type PackagesPathQuery = {
  packageId?: string
  withAdFree?: 'true' | 'false'
  withBinding?: 'true' | 'false'
  return?: string
}

const packagesPath = createPathFunctions<PackagesPathQuery>({
  urlFn: function (query) {
    if (query?.packageId) {
      return defaultUrlFn(`${translatedPaths.packages}/[packageId]`, [], query)
    }
    return defaultUrlFn(translatedPaths.packages, [], query ?? {})
  },
  isActiveFn: (router) => isActivePath(router, 'packages'),
})

export declare type VideoPathQuery = {
  isAutoplay?: boolean
  time?: string
}

export const paths = {
  abroad: createPathFunctions<{ returnTo: string }>({ pathname: translatedPaths.abroad }),
  allPrograms: createPathFunctions<{ genre?: string; letter?: string; hidePlus?: string }>({
    pathname: translatedPaths.allPrograms,
  }),
  article: createPathFunctions<{ articleId: string; source?: string }>({ pathname: '/[articleId]' }),
  categories: createPathFunctions({ pathname: translatedPaths.categories }),
  checkout: createPathFunctions<{
    packageId: string
    return?: string
    from?: 'campaign' | 'adjust-current'
    voucherCode?: string
    withBinding?: 'true' | 'false'
    withAds?: 'true' | 'false'
    /**
     * @deprecated
     */
    binding?: string
  }>({
    urlFn: function (query) {
      return defaultUrlFn(`${translatedPaths.checkout}/[packageId]`, [], query)
    },
    isActiveFn: (router) => isActivePath(router, 'checkout'),
  }),
  checkoutOutcomeCancel: createPathFunctions<{ outcome: string; return?: string }>({
    pathname: `${translatedPaths.checkoutOutcomeCancel}/[outcome]`,
  }),
  checkoutOutcomeDowngrade: createPathFunctions<{ outcome: string; return?: string }>({
    pathname: `${translatedPaths.checkoutOutcomeDowngrade}/[outcome]`,
  }),
  checkoutOutcomeReactivate: createPathFunctions<{ outcome: string; return?: string }>({
    pathname: `${translatedPaths.checkoutOutcomeReactivate}/[outcome]`,
  }),
  checkoutOutcomeAlterCurrent: createPathFunctions<{ outcome: string; return?: string }>({
    pathname: `${translatedPaths.checkoutOutcomeAlterCurrent}/[outcome]`,
  }),
  checkoutOutcomeUpgrade: createPathFunctions<{
    resultCode: ResultCodeEnum
    return?: string
    transactionId?: string
    priceAmount?: string
  }>({
    pathname: `${translatedPaths.checkoutOutcomeUpgrade}/[resultCode]`,
  }),
  checkoutPayment: createPathFunctions<{ packageId: string; return?: string; voucherCode?: string }>({
    urlFn: function (query) {
      return defaultUrlFn(`${translatedPaths.checkoutPayment}/[packageId]`, [], query)
    },
    isActiveFn: (router) => isActivePath(router, 'checkoutPayment'),
  }),
  changePassword: createPathFunctions<{ token: string }>({
    pathname: `${translatedPaths.changePassword}/[token]`,
    isActiveFn: (router) => isActivePath(router, 'changePassword'),
  }),
  clips: clipsPath,
  faq: createPathFunctions<{ source?: string; section?: string }>({
    hashParams: ['section'],
    pathname: translatedPaths.faq,
    isActiveFn: (router) => isActivePath(router, 'faq'),
  }),
  channels: createPathFunctions({
    pathname: translatedPaths.channels,
    isActiveFn: (router) => isActivePath(router, 'channels') || isActivePath(router, 'channelsTableau'),
  }),
  channelsTableau: createPathFunctions<{ d?: string }>({
    pathname: translatedPaths.channelsTableau,
  }),
  sport: createPathFunctions({
    pathname: translatedPaths.sport,
    isActiveFn: (router) => isActivePath(router, 'sport'),
  }),
  sportEpg: createPathFunctions<{ d?: string }>({
    pathname: translatedPaths.sportEpg,
    isActiveFn: (router) => isActivePath(router, 'sport'),
  }),
  forgotPassword: createPathFunctions({
    pathname: translatedPaths.forgotPassword,
    isActiveFn: (router) => isActivePath(router, 'forgotPassword'),
  }),
  iframeVideo: createPathFunctions<{ assetId: string; autoload?: boolean }>({
    pathname: `${translatedPaths.videoEmbed}/[assetId]`,
    isActiveFn: (router) => isActivePath(router, 'videoEmbed'),
  }),
  list: createPathFunctions<{ id: string; slug: string }>({
    urlFn: function (query) {
      return defaultUrlFn(`${translatedPaths.list}/[id]/[slug]`, [], { id: query.id, slug: query.slug })
    },
  }),
  myList: createPathFunctions({ pathname: translatedPaths.myList }),
  news: createPathFunctions({ pathname: translatedPaths.news, isActiveFn: (router) => isActivePath(router, 'news') }),
  page: pagePath,
  program: programsPath,
  search: createPathFunctions<{ q?: string; return?: string } | never>({ pathname: `${translatedPaths.search}/[q]` }),
  start: createPathFunctions({ pathname: '/' }),
  settings: createPathFunctions<{ activeSection?: string } | never>({
    pathname: translatedPaths.settings,
    isActiveFn: (router) => isActivePath(router, 'settings'),
  }),
  shorts: createPathFunctions<{ id: string; slug: string; panelId?: string }>({
    urlFn: function (query) {
      return defaultUrlFn(`${translatedPaths.shorts}/[id]/[slug]`, [], query)
    },
  }),
  shortsRedirect: createPathFunctions({ pathname: translatedPaths.shorts }),
  video: createPathFunctions<AssetIdQuery, VideoPathQuery>({
    urlFn: function (query) {
      return defaultUrlFn(`${translatedPaths.video}/[assetId]/[slug]`, [], {
        assetId: query.assetId,
        slug: query.slug,
        ...(query.time ? { time: query.time } : {}),
        ...(query.isAutoplay ? { isAutoplay: query.isAutoplay.toString() } : {}),
      })
    },
    isActiveFn: (router) => isActivePath(router, 'video'),
  }),
  receipts: createPathFunctions({ pathname: translatedPaths.receipts }),
  paymentMethods: createPathFunctions({ pathname: translatedPaths.paymentMethods }),
  addPaymentMethods: createPathFunctions<{ error?: string }>({ pathname: translatedPaths.addPaymentMethods }),
  packages: packagesPath,
  campaign: createPathFunctions<{ id: string }>({ pathname: `${translatedPaths.campaign}/[id]` }),
  selectProfile: createPathFunctions<{ return?: string }>({
    pathname: translatedPaths.selectProfile,
    isActiveFn: (router) => isActivePath(router, 'selectProfile'),
  }),
  editProfiles: createPathFunctions<{ return?: string }>({
    pathname: translatedPaths.editProfiles,
    isActiveFn: (router) => isActivePath(router, 'editProfiles'),
  }),
  editProfile: createPathFunctions<{ id: string; return?: string }>({
    pathname: `${translatedPaths.editProfile}/[id]`,
    isActiveFn: (router) => isActivePath(router, 'editProfile'),
  }),
  createProfile: createPathFunctions<{ return?: string }>({
    pathname: translatedPaths.createProfile,
    isActiveFn: (router) => isActivePath(router, 'createProfile'),
  }),
  deleteProfile: createPathFunctions<{ id: string; return?: string }>({
    pathname: `${translatedPaths.deleteProfile}/[id]`,
    isActiveFn: (router) => isActivePath(router, 'deleteProfile'),
  }),
  activateOperator: createPathFunctions({
    pathname: translatedPaths.activateOperator,
    isActiveFn: (router) => isActivePath(router, 'activateOperator'),
  }),
}

export const pathsPlayingVideos: Path<AssetIdQuery>[] = [
  paths.video as unknown as Path<AssetIdQuery>,
  paths.clips as unknown as Path<AssetIdQuery>,
] // Typescript can't seem to figure out this is okay :/

export const pathsWithoutCookieConsent = [paths.iframeVideo]
