import type { AdyenCheckout, PaymentAction, SubmitData, UIElement, UIElementProps } from '@adyen/adyen-web/auto'
import type { ResultCodeEnum } from '@nordic-web/rest-codegen/generated/subscription'

export type AdyenCheckoutOptions = Parameters<typeof AdyenCheckout>[0]
export type AdyenCheckoutError = Parameters<NonNullable<AdyenCheckoutOptions['onError']>>[0]

// TODO: can we move to use the types from the graphl codegen instead?
export type Conversions = 'UPGRADE' | 'DOWNGRADE' | 'CANCEL' | 'REACTIVATE' | 'ALTER'

export enum AdyenComponentErrorNames {
  NETWORK_ERROR = 'NETWORK_ERROR',
  CANCEL = 'CANCEL',
  IMPLEMENTATION_ERROR = 'IMPLEMENTATION_ERROR',
  ERROR = 'ERROR',
}
export type AdyenSubmitData = SubmitData

export type AdyenSubmitComponent = UIElement<UIElementProps>

export class FakeAdyenCheckoutError extends Error {
  cause: unknown
  options: LegitimateAny
  constructor(type: AdyenComponentErrorNames) {
    super(type)
    this.name = type
  }
}

export type AdyenResponseObject = {
  action?: PaymentAction
  resultCode: ResultCodeEnum
  transactionId?: string
}

export enum Outcome {
  SUCCESS = 'success',
  ERROR = 'error',
  CONFIRM = 'confirm',
}

export enum PaymentMethodEnum {
  CARD = 'card',
  KLARNA_PAYNOW = 'klarna_paynow',
  KLARNA_PAYLATER = 'klarna',
  STORED_CARD = 'stored_card',
}

export enum OrderPeriods {
  DAY = 'P1D',
  WEEK = 'P1W',
  MONTH = 'P1M',
}
